import React, { Component } from 'react';
import {connect} from "react-redux";
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import moment from 'moment';
import _ from 'lodash';
import {Grid, Container, Button, Menu, Tab, Message, Segment} from 'semantic-ui-react';
import MultiSelect from '@khanacademy/react-multi-select';
import UserCard from './UserCard';
import './assign-shifts.css';
import {getEvent, updateApplicant} from "../../../actions/event";
import ShiftCard from "./ShiftCard";
import RoleCard from "./RoleCard";
import Pagination from './EventDatePagination/Pagination';
import ServerErrors from "../../Common/ServerErrors";
import ServerSuccess from "../../Common/ServerSuccess"
import {convertToString} from "../../../utils/common";

const stateToProps=(state)=>{
  return{
    waitingRequest:state.event.waitingRequest || state.common.waitingRequest,
    event: Object.assign({}, state.event.event),
    success: state.event.success,
    error: state.event.error
  }
};

const dispatchToProps=(dispatch)=>{
  return{
    getEvent:(id)=>dispatch(getEvent(id)),
    updateApplicant:(id,data)=>dispatch(updateApplicant(id,data)),
  }
};

class AssignShifts extends Component {

  constructor(props){
    super(props);
    this.state={
      event: props.event,
      applicants:[],
      selectedRoleIds: [],
      filteredApplicantsBySelectedRoles:[],
      selectedEventDay: null,
      eventDayOptions:[],
      activePage: 1,
      bookedRoles: {},
      bookedShifts: [],
      assignmentError: null,
      draggedApplicant: null,
      formSubmitted: false,
      waitingRequest: props.waitingRequest
    }
  }

  componentDidMount(){
    this.eventId = this.props.match.params['event_id'];
    this.props.getEvent(this.eventId);
  }

  componentWillReceiveProps(nextProps) {
    const {event} = nextProps;
    let {bookedShifts, selectedRoleIds, selectedEventDay, formSubmitted} = this.state;
    const applicants = [];

    event['talent_roles'] = event['talent_roles'].map(role => {
      role.shiftSlots = role.shifts.map((shift) => {
        return {id: shift.id, filled_count: 0, assignedTalents: []};
      });

      role.shifts = role.shifts.map((shift) => {
        let slots = [];
        for (let i = 0; i < role.count; i++) {
          slots.push({isFilled: false});
        }

        return {...shift, slots: slots};
      });

      let roleSlots = [];
      for (let i = 0; i < role.count; i++) {
        roleSlots.push({show: true});
      }

      role.slots = roleSlots;

      const eventDays = [];
      _.each(role.shifts, shift => {

        // don't include shifts having no applicant
        if (shift.applicants.length === 0) {
          return;
        }

        if (eventDays.length === 0) {
          const eventDay = shift['event_day'];
          eventDay['shifts'] = [];
          eventDay['shifts'].push(shift);
          eventDays.push(eventDay);
        } else {
          const eventDay = eventDays.find(eventDay => eventDay.id === shift['event_day'].id);
          if (eventDay) {
            eventDay.shifts.push(shift);
          } else {
            const eventDay = shift['event_day'];
            eventDay['shifts'] = [];
            eventDay['shifts'].push(shift);
            eventDays.push(eventDay);
          }
        }

        _.each(shift.applicants, applicant => {
          const foundApplicant = applicants.find(app => app.talent.id === applicant.talent.id);
          if (!foundApplicant) {
            applicants.push({talent: applicant.talent, shifts: [shift]});
          } else {
            foundApplicant.shifts.push(shift);
          }

          const isInBookedShifts = bookedShifts.find(booking => booking.shift.id === applicant['shift'].id && booking.applicant.talent.id === applicant.talent.id);
          // don't create bookedShifts from server response if form is submitted.
          // we already have bookedShifts in state.
          if (!formSubmitted && applicant.status === 'booked' && !isInBookedShifts) {
            bookedShifts.push({shift, applicant});
          }
        });
      });

      role['event_days'] = eventDays;
      return role;
    });

    const eventDayOptions = event['event_days'].map((eventDay, index) => {
      return {key: index, value: eventDay.id, text: eventDay.date}
    });

    eventDayOptions.sort((a, b) => {
      let dateA = moment(a.text, 'YYYY-MM-DD').toDate();
      let dateB = moment(b.text, 'YYYY-MM-DD').toDate();
      return dateA - dateB;
    });

    if (selectedRoleIds.length === 0) {
      selectedRoleIds = event['talent_roles'].map(role => role.id);
    }
    const filteredApplicantsBySelectedRoles = [];
    let selectedRoles = event['talent_roles'].filter(role => !!selectedRoleIds.find(selectedRoleId => selectedRoleId === role.id));
    selectedRoles.map(role => {
      _.each(role.shifts, shift => {
        _.each(shift.applicants, applicant => {
          const foundApplicant = filteredApplicantsBySelectedRoles.find(app => app.talent.id === applicant.talent.id);
          if (!foundApplicant) {
            filteredApplicantsBySelectedRoles.push({talent: applicant.talent, portfolio: applicant.portfolio_detail, galleryphoto: applicant.galleryphoto, shifts: [shift]});
          } else {
            foundApplicant.shifts.push(shift);
          }
        });
      });
    });

    this.setState({
      event,
      selectedRoleIds,
      applicants,
      filteredApplicantsBySelectedRoles,
      eventDayOptions,
      bookedShifts,
      selectedEventDay: selectedEventDay ? selectedEventDay : event['event_days'][0],
      waitingRequest: nextProps.waitingRequest
    }, () => this.setBadgeCount(true));
  }

  setBadgeCount = (isInit) =>{
    const {event,bookedShifts} = this.state;
    const sortedEventDays = event.event_days.sort((a, b)=>{
      let dateA = moment(a.date,'YYYY-MM-DD').toDate();
      let dateB = moment(b.date,'YYYY-MM-DD').toDate();
      return dateA - dateB;
    });

    const eventDays=sortedEventDays.map((eventDay)=>{
      let totalSlotsCount =0;
      let bookedSlotsCount =0;

      event.talent_roles.forEach((talent_role)=>{
        const staffCount = talent_role.count;

        const shifts = talent_role.shifts.filter((shift)=>{
          return talent_role.id === shift.talent_role.id && shift.event_day.id === eventDay.id;
        });

        totalSlotsCount = totalSlotsCount + (shifts.length * staffCount);
      });

      if (!isInit){
        const bookedShiftsByEventDay = bookedShifts.filter((item)=>{
          return item.shift.event_day.id === eventDay.id;
        });

        const groupByBookedShifts = _.chain((bookedShiftsByEventDay))
          .groupBy((shift)=>{
            return shift.shift.id;
          }).map((item)=>{
            return item;
          }).value();

        groupByBookedShifts.forEach((item)=>{
          bookedSlotsCount = bookedSlotsCount + item.length;
        });
      }

      return {...eventDay, eventDay:eventDay.text,slotsRemaining:totalSlotsCount - bookedSlotsCount};
    });

    this.setState({event: {...event, event_days: eventDays}})
  };

  assignByRole = (applicant, role) => {
    let {bookedRoles, bookedShifts,event} = this.state;

    if (role.id in bookedRoles) {
        bookedRoles[role.id].push(applicant);
    }else{
      bookedRoles[role.id] = [];
      bookedRoles[role.id].push(applicant);
    }

    const roleBookedShifts = [];
    _.each(role['event_days'], eventDay => {
        _.each(eventDay.shifts, shift => {
            const isAlreadyBooked = bookedShifts.find(bookedShift => bookedShift.shift.id === shift.id && bookedShift.applicant.talent.id === applicant.talent.id)
            if (!isAlreadyBooked) {
                roleBookedShifts.push({shift, applicant});
            }
        });
    });

    const talentRoles= event.talent_roles.map((talentRole)=> {
      if (talentRole.id === role.id){
        const shiftSlots = talentRole.shiftSlots.map((item)=>{
          const foundRoleBookedShift = roleBookedShifts.find((roleBookedShift)=>{
            return roleBookedShift.shift.id === item.id;
          });

          if (foundRoleBookedShift){
            return {...item, filled_count : parseInt(item.filled_count,10) + 1};
          }

          return item;
        });

        const maxFilledCount = _.maxBy(shiftSlots, function(o) {
          return o.filled_count;
        }).filled_count;

        const shifts = talentRole.shifts.map((s)=>{
          s.slots[maxFilledCount-1].isFilled = true;
          s.slots[maxFilledCount-1].assignedTalent = applicant.talent;

          return s;
        });

        return {...talentRole, shifts: shifts, shiftSlots: shiftSlots};
      }

      return talentRole;
    });

    bookedShifts = [...bookedShifts, ...roleBookedShifts];
    this.setState({ bookedRoles, bookedShifts, event: {...event, talent_roles: talentRoles} }, ()=>this.setBadgeCount(false));
  };

  assignByShift = (applicant, shift) => {
    const { bookedShifts,bookedRoles } = this.state;
    const role= this.state.event.talent_roles.find((role)=> role.id === shift.talent_role.id);

    bookedShifts.push({ shift, applicant });
    this.setState({ bookedShifts, assignmentError: null }, ()=> this.setBadgeCount(false));

    const shiftSlot = role.shiftSlots.find((item)=>{
      return item.id === shift.id;
    });

    if (shiftSlot) {
      shiftSlot.filled_count = parseInt(shiftSlot.filled_count, 10) + 1;
      shiftSlot.assignedTalents.push(applicant.talent);

      const filledCount = shiftSlot.filled_count;
      let shouldShowSlot = false;
      let userIds = [];

      for (let i = 0; i < role.shiftSlots.length; i++) {
        if (role.shiftSlots[i].assignedTalents[filledCount-1]){
          const userId = role.shiftSlots[i].assignedTalents[filledCount-1].user.id;
          userIds.push(userId);
        }
      }

      const userIdsAreEqual = userIds.every( v => v === userIds[0] );
      if (userIds.length === role.shiftSlots.length && userIdsAreEqual){
        shouldShowSlot = true;
      }

      role.slots[filledCount-1].show = shouldShowSlot;

      role.shifts.map((s)=>{
        if (s.id === shift.id){
          s.slots[filledCount-1].isFilled = true;
          s.slots[filledCount-1].assignedTalent = applicant.talent;
        }

        return s;
      });
    }

    const maxFilledCount = _.maxBy(role.shiftSlots, function(o) {
      return o.filled_count;
    }).filled_count;

    let applicants = [];
    if (role.id in bookedRoles) {
      applicants=bookedRoles[role.id];
    }
    bookedRoles[role.id] = [];

    for(let i=0;i <maxFilledCount; i++){
      if (i < (maxFilledCount-1)){
        bookedRoles[role.id].push(applicants[i]);
      } else{
        bookedRoles[role.id].push(applicant);
      }
    }

    this.setState({ bookedRoles });
  };

  removeAssignedByRoleTalent = (roleId, talentId) => {
    let {bookedRoles, bookedShifts, event} = this.state;
    let bookedApplicants = bookedRoles[roleId];

    const talentRoles= event.talent_roles.map((talentRole)=> {
      if (talentRole.id === roleId){
        const removedShifts = bookedRoles[roleId].filter(applicant => applicant.talent.id === talentId)[0].shifts;

        const shiftSlots = talentRole.shiftSlots.map((item)=>{
          const foundRoleBookedShift = removedShifts.find((shift)=>{
            return shift.id === item.id;
          });

          if (foundRoleBookedShift){
            return {...item, filled_count : parseInt(item.filled_count,10) - 1};
          }

          return item;
        });

        return {...talentRole, shiftSlots: shiftSlots};
      }

      return talentRole;
    });

    bookedApplicants = bookedApplicants.filter(applicant => applicant.talent.id !== talentId);
    bookedRoles[roleId] = bookedApplicants;
    bookedShifts = bookedShifts.filter(bookedShift => bookedShift.applicant.talent.id !== talentId);

    this.setState({ bookedRoles, bookedShifts, event: {...event, talent_roles: talentRoles} }, ()=> this.setBadgeCount(false));
  };

  removeAssignedByShiftTalent = (shiftId, applicant, roleId) => {
    let {bookedShifts, bookedRoles, event} = this.state;
    bookedShifts = bookedShifts.filter(bookedShift => {
      if (bookedShift.shift.id === shiftId) {
        return bookedShift.applicant.talent.id !== applicant.talent.id;
      }

      return true;
    });

    let talentRoles = event.talent_roles.map((talentRole) => {
      if (talentRole.id === roleId) {
        const shiftSlots = talentRole.shiftSlots.map((item) => {
          if (item.id === shiftId) {
            const assignedTalents = item.assignedTalents.filter((assignedtalent)=>{
              return assignedtalent.user.id !== applicant.talent.user.id;
            });

            const filledCount = parseInt(item.filled_count, 10) - 1;
            return {...item, assignedTalents: assignedTalents, filled_count: filledCount};
          }

          return item;
        });

        return {...talentRole, shiftSlots: shiftSlots};
      }

      return talentRole;
    });

    talentRoles = talentRoles.map((talentRole) => {
      if (talentRole.id === roleId) {
        for(let i=0;i<talentRole.count;i++) {
          let shouldShowSlot = false;
          let userIds = [];

          for (let j = 0; j < talentRole.shiftSlots.length; j++) {
            const assignedTalent = talentRole.shiftSlots[j].assignedTalents[i];

            if (assignedTalent) {
              userIds.push(assignedTalent.user.id);
            }
          }

          if (userIds.length === 0) {
            shouldShowSlot = true;
          } else {
            const userIdsAreEqual = userIds.every(v => v === userIds[0]);
            if (userIds.length === talentRole.shiftSlots.length && userIdsAreEqual) {
              shouldShowSlot = true;
            }
          }

          talentRole.slots[i].show = shouldShowSlot;
        }
      }

      return talentRole;
    });

    const role = talentRoles.find((item) => item.id === roleId);
    const maxFilledCount = _.maxBy(role.shiftSlots, function (o) {
      return o.filled_count;
    }).filled_count;

    let applicants = [];
    if (role.id in bookedRoles) {
      applicants = bookedRoles[role.id];
    }
    bookedRoles[role.id] = [];

    for (let i = 0; i < maxFilledCount; i++) {
      if (i < (maxFilledCount - 1)) {
        bookedRoles[role.id].push(applicants[i]);
      } else {
        const s = role.shiftSlots.find((item)=>{
          return item.filled_count === maxFilledCount;
        });

        bookedRoles[role.id].push({talent: s.assignedTalents[0]});
      }
    }

    this.setState({bookedShifts, bookedRoles, event: {...event, talent_roles: talentRoles}}, ()=> this.setBadgeCount(false));
  };

  applicantDragStarted = (applicant) => {
     this.setState({ draggedApplicant: applicant });
  };

  applicantDragEnded = () => {
      this.setState({ draggedApplicant: null });
  };

  save = () => {
    this.setState({ formSubmitted: true }, () => {
       const {bookedShifts} = this.state;
        const bookings = bookedShifts.map(booking => {
            const applicant = booking.shift.applicants.find(applicant => applicant.talent.id===booking.applicant.talent.id);
            return {
                id: applicant.id,
                event_shift: booking.shift.id,
                talent: applicant.talent.id,
                portfolio: applicant.portfolio,
                status: 'booked'
            }
        });
        this.props.updateApplicant(this.eventId, bookings);
    });
  };

  cancel = () => {
    this.props.history.push(`/jobdetails/${this.eventId}`);
  };

  handlePageChange = (pageNumber) => {
    const {event} = this.props;
    let eventDay = this.state.eventDayOptions[pageNumber-1];
    const selectedEventDay = event['event_days'][pageNumber-1]
    this.setState({activePage: pageNumber,event_day:eventDay.value, selectedEventDay});
  };

  handleRolesSelectedChanged = (selectedRoleIds) => {
      const {event} = this.state;
      const filteredApplicantsBySelectedRoles = [];
      let selectedRoles = event['talent_roles'].filter(role => !!selectedRoleIds.find(selectedRoleId => selectedRoleId === role.id));
      selectedRoles.map(role => {
          _.each(role.shifts, shift => {
             _.each(shift.applicants, applicant => {
                 const foundApplicant = filteredApplicantsBySelectedRoles.find(app => app.talent.id === applicant.talent.id);
                 if (!foundApplicant){
                    filteredApplicantsBySelectedRoles.push({talent: applicant.talent, portfolio: applicant.portfolio_detail, galleryphoto: applicant.galleryphoto, shifts: [shift]});
                 }
                 else{
                    foundApplicant.shifts.push(shift);
                 }
              });
          });
      });

      this.setState({ selectedRoles, selectedRoleIds, filteredApplicantsBySelectedRoles });
  };

  getRoleCards = () => {
      const colors=['#31DBDA','#3187DB','#AF31DB','#DB3132','#DB8531','#31DB85','#3132DB','#AF31DB','#DB3187'];
      const {event, bookedRoles, bookedShifts, applicants, draggedApplicant} = this.state;
      return event['talent_roles'].map((role, index)=> {
      return (
        <Grid.Column width={5}>
          <h2 style={{color:colors[index]}}>{role.name}</h2>
          <RoleCard
              role={role}
              bookedRoles={bookedRoles}
              bookedShifts={bookedShifts}
              applicants={applicants}
              draggedApplicant={draggedApplicant}
              removeAssignedByRoleTalent={this.removeAssignedByRoleTalent}
              key={index}
          />
        </Grid.Column>
      );
    });
  };

  getShiftCards = () => {
    const {bookedShifts, draggedApplicant, selectedEventDay, event} = this.state;
    let roleCards = [];
    if (selectedEventDay) {
      const shifts = selectedEventDay['shifts'].map((shift, index) => {
        const shiftRole = event['talent_roles'].find(role => {
          return role.shifts.find(s => s.applicants.length > 0 && s.id === shift.id);
        });

        if (!shiftRole) {
          return;
        }

        shift = shiftRole.shifts.find(s => s.id === shift.id);
        return {shift: shift, role: shiftRole};
      });

      const filteredShifts = shifts.filter((shift)=>{
        return !!shift;
      });

      const groupByShifts = _.chain((filteredShifts))
        .groupBy((shift) => {
          return shift.role.id;
        }).map((item) => {
          return item;
        }).value();

      roleCards = groupByShifts.map((groupByShift,i)=>{
        const roleName = groupByShift[0].role.name;
        const roleId = groupByShift[0].role.id;

        const shiftCards = groupByShift.map((shift)=>{
          return (
            <Grid.Column width={8}>
              <ShiftCard
                shift={shift.shift}
                role={shift.role}
                bookedShifts={bookedShifts}
                draggedApplicant={draggedApplicant}
                removeAssignedByShiftTalent={this.removeAssignedByShiftTalent}
                key={shift.id}
              />
            </Grid.Column>
          );
        });

        return (
          <Grid.Column width={8}>
            {roleName}
            <Segment key={roleId}>
              <Grid>
                <Grid.Row>
                  {shiftCards}
                </Grid.Row>
              </Grid>
            </Segment>
          </Grid.Column>
        );
      });
    }

    return roleCards;
  };

  getApplicantList = () => {
      const {filteredApplicantsBySelectedRoles, applicants} = this.state;
      return filteredApplicantsBySelectedRoles.map( applicant =>{
      return(
        <UserCard
            key={applicant.id}
            applicantList={applicants}
            applicant={applicant}
            assignByRole={this.assignByRole}
            assignByShift={this.assignByShift}
            applicantDragStarted={this.applicantDragStarted}
            applicantDragEnded={this.applicantDragEnded}
        />
      )
    });
  };

  getTalentRoleOptions = () => {
      const {event} = this.state;
      return event['talent_roles'].map( role =>{
          return {label: role.name, value: role.id};
        });
  };

  render(){
    const {success, error} = this.props;
    const {event, formSubmitted, assignmentError, waitingRequest} = this.state;
    const roleCards = this.getRoleCards();
    const shiftCards = this.getShiftCards();
    const talentRoleOptions = this.getTalentRoleOptions();
    const applicantList = this.getApplicantList();

    const panes = [
      {   menuItem: 'Assign by Roles',
          render: () =>
            <Tab.Pane attached={false}>
              <Container fluid>
                <Grid>
                  <Grid.Row style={{padding:'10px'}}>
                    {roleCards}
                  </Grid.Row>
                </Grid>
              </Container>
            </Tab.Pane>
      },

      {   menuItem: 'Assign by Shift',
          render: () =>
            <Tab.Pane attached={false}>
              <Container fluid>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={16}>
                      <div style={{display:'inline',float:'right'}}>
                      </div>
                      <Pagination
                        activePage={this.state.activePage}
                        itemsCountPerPage={1}
                        totalItemsCount={event.event_days.length}
                        eventDays={event.event_days}
                        pageRangeDisplayed={2}
                        onChange={this.handlePageChange}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row style={{padding:'10px'}}>

                      {assignmentError &&
                        <Message warning>
                            <p>{assignmentError}</p>
                        </Message>
                      }

                      {shiftCards}

                  </Grid.Row>
                </Grid>
              </Container>
            </Tab.Pane>
      },

      { menuItem:  (
          <Menu.Menu position='right'>
            <Button size='large' color={'blue'} onClick={() => this.save()}>Save</Button>
            <Button size='large' loading={this.props.waitingRequest} onClick={() => this.cancel()}>Cancel</Button>
          </Menu.Menu>
        ),
        render: () => <Tab.Pane attached={false}>Tab 2 Content</Tab.Pane> },
    ];

    return(
      <Container className={'main-container'}>
        <Grid stackable padded>
          <Grid.Row className={'content-row'}>
            <Grid.Column mobile={16} tablet={6} computer={6} className={'users-column'}>
              <MultiSelect
                options={talentRoleOptions}
                onSelectedChanged={selected => this.handleRolesSelectedChanged(selected)}
                selected={this.state.selectedRoleIds}
                disableSearch={true}
                overrideStrings={{
                  selectSomeItems: "Select roles...",
                  allItemsAreSelected: "All roles",
                  selectAll: "Select all",
                  search: "Search roles",
                }}
              />
              <Container>
                <div className={'container-applicant-list'}>
                  {applicantList}
                </div>
              </Container>

            </Grid.Column>
            <Grid.Column mobile={16} tablet={10} computer={10} className={'shifts-column'}>

                {!waitingRequest && formSubmitted && success && <ServerSuccess successMessage={'Assignments done successfully.'} />}
                {!waitingRequest && formSubmitted && error && <ServerErrors errorMessage={convertToString(error)} />}

                <Tab style={{width:'100%'}} menu={{ secondary: true, }} panes={panes} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    )
  }
}

export default DragDropContext(HTML5Backend)(connect(stateToProps,dispatchToProps)(AssignShifts));
